/* eslint-disable eqeqeq */
import { useEffect, useState } from 'react';
import InApp from 'detect-inapp';
import * as Sentry from '@sentry/browser';

async function iosDetection() {
	const iosInAppResolutions = {
		'932': [746],
		'852': [666],
		'926': [752],
		'844': [670],
		'812': [635, 641],
		'667': [559],
		'896': [725, 721],
		'736': [628],
		'568': [460]
	};
	const is_ios_supported = !!navigator.userAgent.match(
		/iPhone OS 15_|iPhone OS 16_|iPhone OS 17_/i
	);
	const is_ios17 = !!navigator.userAgent.match(/iOS 17/i);
	const is_safari =
		!!navigator.userAgent.match(/Safari/i) &&
		!!navigator.userAgent.match(/Mobile/i) &&
		!!navigator.userAgent.match(/Version/i);
	const screen_h = window.screen.height;
	const window_h = window.innerHeight;
	if (
		!iosInAppResolutions[`${screen_h}` as keyof typeof iosInAppResolutions]
	) {
		Sentry.captureEvent({
			message: 'IOS resolution not supported',
			level: 'warning',
			extra: {
				screen_h,
				window_h
			}
		});
		return false;
	}
	if (
		is_ios_supported &&
		is_safari &&
		iosInAppResolutions[
			`${screen_h}` as keyof typeof iosInAppResolutions
		] &&
		iosInAppResolutions[`${screen_h}` as keyof typeof iosInAppResolutions]
			.length
	) {
		if (screen_h == 812 && window_h == 635) return is_ios17; // ambiguity case
		return (
			iosInAppResolutions[
				`${screen_h}` as keyof typeof iosInAppResolutions
			].indexOf(window_h) !== -1
		);
	}
	return false;
}

function androidDetection() {
	const inApp = new InApp(navigator.userAgent || navigator.vendor);

	return inApp.isInApp;
}

export const browserInAppDetector = () => {
	const [isWebView, setIsWebView] = useState<boolean | undefined>(undefined);
	const [userAgent, setUserAgent] = useState('');

	useEffect(() => {
		const _userAgent = window.navigator.userAgent.toLowerCase(),
			ios = /iphone|ipod|ipad/.test(_userAgent);

		if (ios) {
			(async () => {
				setIsWebView(await iosDetection());
			})();
		} else setIsWebView(androidDetection());

		setUserAgent(_userAgent);
	}, []);

	return {
		isWebView,
		userAgent
	};
};
